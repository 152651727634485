var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('breadcrumb',{attrs:{"items":_vm.breadcrumb}}),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Wyszukaj","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticStyle:{"text-align":"right"}},[_c('v-btn',{attrs:{"color":"secondary_dark","dark":"","depressed":"","tile":"","to":"/rola-nowa"}},[_vm._v(" Dodaj nową ")])],1)],1),_c('v-data-table',{staticClass:"classic_table",attrs:{"headers":_vm.headers,"items":_vm.list,"search":_vm.search,"item-key":"name","footer-props":{
      itemsPerPageText : 'Elementów na stronie',
      itemsPerPageAllText : 'Wszystkie',
      itemsPerPageOptions : [15,45,90,-1],
    }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v("Brak danych")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v("Brak wyników")]},proxy:true},{key:"footer.page-text",fn:function(ref){
    var pageStart = ref.pageStart;
    var pageStop = ref.pageStop;
    var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+"-"+_vm._s(pageStop)+" z "+_vm._s(itemsLength)+" ")]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"color":"secondary_dark","outlined":""},on:{"click":function($event){return _vm.roleClick(item)}}},[_vm._v("Edytuj role")]),_c('v-btn',{staticStyle:{"margin-left":"8px"},attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.dialog=true;_vm.deleted_user=item}}},[_vm._v("Usuń role")])]}}])}),(_vm.dialog)?_c('confirm_dialog',{attrs:{"text":"Czy na pewno chcesz usuąć role?"},on:{"close":function($event){_vm.dialog = false;},"confirm":function($event){return _vm.delete_role();}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }