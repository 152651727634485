<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row justify="end">
      <v-col>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Wyszukaj"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col style="text-align: right">
        <v-btn
          color="secondary_dark"
          dark
          depressed
          tile
          to="/rola-nowa"
        >
          Dodaj nową
        </v-btn>
      </v-col>
    </v-row>
    
    <v-data-table
      class="classic_table"
      :headers="headers"
      :items="list"
      :search="search"
      item-key="name"
      :footer-props="{
        itemsPerPageText : 'Elementów na stronie',
        itemsPerPageAllText : 'Wszystkie',
        itemsPerPageOptions : [15,45,90,-1],
      }"
    >
      <template v-slot:no-data>Brak danych</template>
      <template v-slot:no-results>Brak wyników</template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} z {{ itemsLength}}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          @click="roleClick(item)"
          color="secondary_dark"
          outlined
        >Edytuj role</v-btn>
        <v-btn
          @click="dialog=true;deleted_user=item"
          color="error"
          outlined
          style="margin-left: 8px"
        >Usuń role</v-btn>
      </template>

    </v-data-table>

    <confirm_dialog
      v-if="dialog"
      text="Czy na pewno chcesz usuąć role?"
      @close="dialog = false;"
      @confirm="delete_role();"
    />
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista ról',
        disabled: true,
        to: '/role',
      },
    ],

    headers: [
      {
        text: 'ID',
        align: 'start',
        value: 'id',
        width: '5%'
      },
      { text: 'Role', value: 'name' },
      { text: 'Akcja', value: 'action' }
    ],

    list: [
      {
        id: 1,
        name: 'Nazwa'
      },
    ],

    deleted_user: {},
    
    search: ''
  }),
  methods: {
    get_data(){
      this.$store.commit('loader');
      this.$axios({url: this.$store.state.api +'/auth/role', data: {}, method: 'GET' })
        .then(resp => {
          this.list = resp.data;
          console.log(resp.data);
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    },
    roleClick(value) {
      this.$router.push('/rola/'+value.id);
    },
    delete_role(){
      this.dialog = false;
      this.$store.commit('loader');
      this.$axios({url: this.$store.state.api +'/auth/role/'+this.deleted_user.id, data: {}, method: 'DELETE' })
        .then(resp => {
          this.$store.commit("snackbar", {
            text: "Usunięto role",
            color: "primary",
            btn_color: "white"
          });
          this.get_data();
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    }
  },
  mounted(){
    this.get_data();
  }
};
</script>

<style lang="scss" scoped>
.classic_table {
  margin-top: 25px;
}
</style>